.container {
  display: flex;
  min-height: 100vh;
  min-width: 99vw;
  margin: 0;
  background-image: linear-gradient(180deg, #f6d365 0%, #ff8e6f 100%);
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: space-around;
}

.search-form {
  width: 100%;
  min-height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-bar {
  width: 50%;
  border: 2px solid #bdbdbd;
  padding: 10px;
  border-radius: 5px;
}

.search-bar:focus {
  outline: none;
  box-shadow: 0px 0px 2px #686868;
}

@media screen and (max-width: 700px) {
  .search-bar::placeholder {
    font-size: 13px;
  }
}

.search-button {
  background: #ff8563;
  border: none;
  padding: 10px 20px;
  color: white;
  border-radius: 5px;
}

.search-button:hover {
  background-color: #ff754f;
}

.search-button:focus {
  outline: none;
  box-shadow: none;
}

.loading {
  position: fixed;
  z-index: 4000;
  top: 50%;
  left: 50%;
  margin-left: -70px;
}
