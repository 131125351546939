.e-schedule .e-appointment-details {
  padding: 0 !important;
}

.month-template-wrap {
  padding-left: 10px;
  padding-right: 100px;
  padding-bottom: 10px;
  font-weight: 500;
}

.e-navigate {
  padding: 5px;
}

.details {
  background-color: rgb(248, 248, 248);
  width: 100%;
  margin: auto;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #ccc !important;
  margin-top: 15px;
}

.content {
  float: right;
}

.galery-photo {
  background-color: white;
  max-width: max-content;
  margin: 15px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 5px 10px rgb(97, 97, 97);
}

.myimage {
  max-height: 200px;
  max-width: 100%;
  transition: 0.2s;
}

.myimage:hover {
  max-height: 210px !important;
}

.mydelete {
  padding-right: 10px;
}

.delete-icon {
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 50%;
  width: fit-content;
}

.delete-icon:hover {
  background: #ccc;
}

.status {
  background-color: #fff3cd;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -250px;
  max-width: 400px;
  min-width: 300px;
  height: 100px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 5px 10px rgb(97, 97, 97);
}

.cost-cell {
  border: 1px solid black;
  font-size: 18px;
}

@media screen and (max-width: 700px) {
  .cost-cell {
    font-size: 2.8vw;
  }
}

.cost {
  min-width: 50px;
}

.reminderPeriod {
  margin-top: 15px;
}
