body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(
    180deg,
    #f6d365 0%,
    #ff8e6f 50%,
    #f6d365 100%
  );
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  font-size: 20px;
}

html {
  height: 100%;
}

.content {
  width: 100%;
  padding: 20px;
}

.main {
  background-color: white;
  min-width: 320px;
  max-width: 1000px;
  margin: auto;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 5px 10px rgb(97, 97, 97);
}

.navbar {
  padding: 0px 10px 0px 10px !important;
}

.help-msg {
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  background-color: #fff3cd;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -100px;
  margin-left: -200px;
  max-width: 400px;
  min-width: 300px;
  height: auto;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 5px 10px rgb(97, 97, 97);
  z-index: 1000;
}

.container {
  display: flex;
  min-height: 100vh;
  min-width: 99vw;
  margin: 0;
  background-image: linear-gradient(180deg, #f6d365 0%, #ff8e6f 100%);
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: space-around;
}

.search-form {
  width: 100%;
  min-height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-bar {
  width: 50%;
  border: 2px solid #bdbdbd;
  padding: 10px;
  border-radius: 5px;
}

.search-bar:focus {
  outline: none;
  box-shadow: 0px 0px 2px #686868;
}

@media screen and (max-width: 700px) {
  .search-bar::-webkit-input-placeholder {
    font-size: 13px;
  }
  .search-bar:-ms-input-placeholder {
    font-size: 13px;
  }
  .search-bar::placeholder {
    font-size: 13px;
  }
}

.search-button {
  background: #ff8563;
  border: none;
  padding: 10px 20px;
  color: white;
  border-radius: 5px;
}

.search-button:hover {
  background-color: #ff754f;
}

.search-button:focus {
  outline: none;
  box-shadow: none;
}

.loading {
  position: fixed;
  z-index: 4000;
  top: 50%;
  left: 50%;
  margin-left: -70px;
}

.temple {
  background-color: white;
  min-width: 320px;
  max-width: 550px;
  margin: 15px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 5px 10px rgb(97, 97, 97);
  transition: 0.5s;
}

.photo {
  max-width: 100%;
}

.image {
  max-width: 100%;
  max-height: 300px;
}

p {
  font-size: 20px;
}

.temple:hover {
  transform: scale(1.05);
  cursor: pointer;
}

.temple-card {
  color: inherit !important;
  text-decoration: none !important;
}

.e-schedule .e-appointment-details {
  padding: 0 !important;
}

.month-template-wrap {
  padding-left: 10px;
  padding-right: 100px;
  padding-bottom: 10px;
  font-weight: 500;
}

.e-navigate {
  padding: 5px;
}

.details {
  background-color: rgb(248, 248, 248);
  width: 100%;
  margin: auto;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #ccc !important;
  margin-top: 15px;
}

.content {
  float: right;
}

.galery-photo {
  background-color: white;
  max-width: -webkit-max-content;
  max-width: -moz-max-content;
  max-width: max-content;
  margin: 15px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 5px 10px rgb(97, 97, 97);
}

.myimage {
  max-height: 200px;
  max-width: 100%;
  transition: 0.2s;
}

.myimage:hover {
  max-height: 210px !important;
}

.mydelete {
  padding-right: 10px;
}

.delete-icon {
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 50%;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.delete-icon:hover {
  background: #ccc;
}

.status {
  background-color: #fff3cd;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -250px;
  max-width: 400px;
  min-width: 300px;
  height: 100px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 5px 10px rgb(97, 97, 97);
}

.cost-cell {
  border: 1px solid black;
  font-size: 18px;
}

@media screen and (max-width: 700px) {
  .cost-cell {
    font-size: 2.8vw;
  }
}

.cost {
  min-width: 50px;
}

.reminderPeriod {
  margin-top: 15px;
}

.e-schedule .e-appointment-details {
  padding: 0 !important;
}

.month-template-wrap {
  padding-left: 10px;
  padding-right: 100px;
  padding-bottom: 10px;
  font-weight: 500;
}

.e-navigate {
  padding: 5px;
}

.details {
  background-color: rgb(248, 248, 248);
  width: 100%;
  margin: auto;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #ccc !important;
  margin-top: 15px;
}

.content {
  float: right;
}

.mydelete {
  padding-right: 10px;
}

.delete-icon {
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 50%;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.delete-icon:hover {
  background: #ccc;
}

.status {
  background-color: #fff3cd;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -150px;
  max-width: 400px;
  min-width: 300px;
  height: 100px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 5px 10px rgb(97, 97, 97);
}

.e-template {
  display: block !important;
}

.nav-link {
  color: #e78600;
}

.nav-link:hover {
  color: #aa6300;
}

.nav-link.active {
  background-color: #ff9c13 !important;
}

