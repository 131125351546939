body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(
    180deg,
    #f6d365 0%,
    #ff8e6f 50%,
    #f6d365 100%
  );
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  font-size: 20px;
}

html {
  height: 100%;
}

.content {
  width: 100%;
  padding: 20px;
}

.main {
  background-color: white;
  min-width: 320px;
  max-width: 1000px;
  margin: auto;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 5px 10px rgb(97, 97, 97);
}

.navbar {
  padding: 0px 10px 0px 10px !important;
}

.help-msg {
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  background-color: #fff3cd;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -100px;
  margin-left: -200px;
  max-width: 400px;
  min-width: 300px;
  height: auto;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 5px 10px rgb(97, 97, 97);
  z-index: 1000;
}
