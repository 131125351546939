.temple {
  background-color: white;
  min-width: 320px;
  max-width: 550px;
  margin: 15px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 5px 10px rgb(97, 97, 97);
  transition: 0.5s;
}

.photo {
  max-width: 100%;
}

.image {
  max-width: 100%;
  max-height: 300px;
}

p {
  font-size: 20px;
}

.temple:hover {
  transform: scale(1.05);
  cursor: pointer;
}

.temple-card {
  color: inherit !important;
  text-decoration: none !important;
}
