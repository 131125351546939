.nav-link {
  color: #e78600;
}

.nav-link:hover {
  color: #aa6300;
}

.nav-link.active {
  background-color: #ff9c13 !important;
}
