.e-schedule .e-appointment-details {
  padding: 0 !important;
}

.month-template-wrap {
  padding-left: 10px;
  padding-right: 100px;
  padding-bottom: 10px;
  font-weight: 500;
}

.e-navigate {
  padding: 5px;
}

.details {
  background-color: rgb(248, 248, 248);
  width: 100%;
  margin: auto;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #ccc !important;
  margin-top: 15px;
}

.content {
  float: right;
}

.mydelete {
  padding-right: 10px;
}

.delete-icon {
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 50%;
  width: fit-content;
}

.delete-icon:hover {
  background: #ccc;
}

.status {
  background-color: #fff3cd;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -150px;
  max-width: 400px;
  min-width: 300px;
  height: 100px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 5px 10px rgb(97, 97, 97);
}

.e-template {
  display: block !important;
}
